"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainArticleView = exports.MainArticleView = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/document-editor/lib-es2015");
const constants_1 = require("./constants");
const common_1 = require("./common");
class MainArticleView extends HTMLElement {
    constructor() {
        super();
        this.putTitleAndContent = (mainArticleId, title, content) => {
            (0, lib_es2015_2.removeAllChildren)(this.titleViewer);
            (0, lib_es2015_2.removeAllChildren)(this.contentViewer);
            this.mainArticleIdPlaceholder.innerHTML = `${mainArticleId}`;
            this.titleViewer.appendChild((0, common_1.buildPresentableTitleOrEmptyPresentableTitle)(title, this.documentViewer));
            this.contentViewer.appendChild((0, common_1.buildPresentableContentOrEmptyPresentableContent)(content, this.documentViewer));
        };
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.mainArticleId = parseInt((0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_DATA_ID));
            this.isLogin = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_IS_LOGIN) === 'true';
            this.router = (0, lib_es2015_1.useRouter)();
            this.routerPathGroup = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
            this.articleMainTypeName = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_MAIN_TYPE_NAME);
            this.documentViewer = new lib_es2015_3.DocumentViewer();
            this.innerHTML = `
    <div class="secondary-container">
      ${this.articleMainTypeName} <span class="main-article-id"></span>.
      <button class="edit">Edit</button>
    </div>
    <div class="main-article title-viewer"></div>
    <div class="main-article content-viewer"></div>
    `;
            this.mainArticleIdPlaceholder = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'span.main-article-id');
            this.titleViewer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.title-viewer');
            this.contentViewer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.content-viewer');
            const editBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.edit');
            if (!this.isLogin) {
                editBtn.style.setProperty('display', 'none');
            }
            else {
                editBtn.addEventListener('click', e => {
                    this.router.push(`/web/${this.routerPathGroup}/edit/${this.mainArticleId}`);
                });
            }
        });
    }
}
exports.MainArticleView = MainArticleView;
const defineMainArticleView = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, MainArticleView);
};
exports.defineMainArticleView = defineMainArticleView;
