"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainArticleEdit = exports.MainArticleEdit = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/webapp-common/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_4 = require("@hodol/document-editor/lib-es2015");
const constants_1 = require("./constants");
const common_1 = require("./common");
class MainArticleEdit extends HTMLElement {
    constructor() {
        super();
        this.fetchItem = () => __awaiter(this, void 0, void 0, function* () {
            console.log(`fetchItem() called!`);
            const mainArticleEntity = yield this.apis.getMainArticleByKey({
                id: parseInt(this.dataId),
            });
            if (mainArticleEntity === null) {
                console.error(`fetchItem(): mainArticleEntity is null`);
                return;
            }
            console.log(`fetchItem(): start converting`);
            this.titleEditor.appendChild((0, common_1.buildEditableTitleOrInitialEditableTitle)(mainArticleEntity.title, this.documentEditor));
            this.contentEditor.appendChild((0, common_1.buildEditableContentOrInitialEditableContent)(mainArticleEntity.content, this.documentEditor));
            console.log(`fetchItem(): done`);
        });
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.dataId = (0, lib_es2015_3.getAttributeOrThrowError)(this, 'data-id');
            this.router = (0, lib_es2015_1.useRouter)();
            this.isLogin = (0, lib_es2015_2.getServerProps)().member !== undefined;
            this.isNew = this.dataId === '0';
            this.routerPathGroup = (0, lib_es2015_3.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
            this.innerHTML = `
    <div class="toolbar-container"></div>
    <div class="title-editor"></div>
    <div class="content-editor"></div>

    <!-- The following will be moved into toolbar on mounted -->
    <div class="tool-box-group additional-tools">
      <button class="save">Save</button>
      <button class="cancel">Cancel</button>
    </div>
    `;
            this.titleEditor = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.title-editor');
            this.contentEditor = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.content-editor');
            this.toolbarContainer = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.toolbar-container');
            this.additionalTools = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.additional-tools');
            const saveBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.save');
            if (!this.isLogin) {
                saveBtn.style.setProperty('display', 'none');
            }
            else {
                saveBtn.addEventListener('click', e => {
                    const recordableTitle = (0, lib_es2015_3.getFirstElementChildOrThrowError)(this.titleEditor);
                    this.documentEditor.convertEditableToRecordable(recordableTitle);
                    const recordableContent = (0, lib_es2015_3.getFirstElementChildOrThrowError)(this.contentEditor);
                    this.documentEditor.convertEditableToRecordable(recordableContent);
                    void (() => __awaiter(this, void 0, void 0, function* () {
                        if (this.isNew) {
                            const resp = yield this.apis.insertMainArticle({
                                title: this.titleEditor.innerHTML,
                                content: this.contentEditor.innerHTML,
                            });
                            if (resp === null) {
                                alert('fail to post new document!');
                                return;
                            }
                            this.router.push(`/web/${this.routerPathGroup}/view/${resp.id}`);
                        }
                        else {
                            const resp = yield this.apis.updateMainArticle(this.dataId, {
                                title: this.titleEditor.innerHTML,
                                content: this.contentEditor.innerHTML,
                            });
                            if (resp === null) {
                                alert('fail to update document!');
                                return;
                            }
                            this.router.push(`/web/${this.routerPathGroup}/view/${resp.id}`);
                        }
                    }))();
                });
            }
            const cancelBtn = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.cancel');
            cancelBtn.addEventListener('click', e => {
                const answer = confirm(`Your changes will be lost, continue?`);
                if (answer)
                    window.history.back();
            });
            this.documentEditor = new lib_es2015_4.DocumentEditor(this.contentEditor, this.toolbarContainer, '/files/customize1/styles/fonts');
            this.documentEditor.appendToToolbar(this.additionalTools);
            if (!this.isNew) {
                yield this.fetchItem();
            }
            else {
                this.titleEditor.appendChild((0, common_1.buildInitialEditableTitle)(this.documentEditor));
                this.contentEditor.appendChild((0, common_1.buildInitialEditableContent)(this.documentEditor));
            }
        });
    }
}
exports.MainArticleEdit = MainArticleEdit;
const defineMainArticleEdit = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, MainArticleEdit);
};
exports.defineMainArticleEdit = defineMainArticleEdit;
