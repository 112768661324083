"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineSubArticleView = exports.SubArticleView = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/document-editor/lib-es2015");
const constants_1 = require("./constants");
const common_1 = require("./common");
class SubArticleView extends HTMLElement {
    constructor() {
        super();
        this.putContent = (mainArticleId, subArticleId, content) => {
            (0, lib_es2015_2.removeAllChildren)(this.contentViewer);
            this.mainArticleId = mainArticleId;
            this.subArticleId = subArticleId;
            this.subArticleIdPlaceHolder.innerHTML = `${subArticleId}`;
            this.contentViewer.appendChild((0, common_1.buildPresentableContentOrEmptyPresentableContent)(content, this.documentViewer));
        };
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        this.router = (0, lib_es2015_1.useRouter)();
        this.documentViewer = new lib_es2015_3.DocumentViewer();
        this.isLogin = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_IS_LOGIN) === 'true';
        this.routerPathGroup = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_PATH_GROUP);
        this.routerSubPathGroup = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ROUTER_SUB_PATH_GROUP);
        this.articleSubTypeName = (0, lib_es2015_2.getAttributeOrThrowError)(this, constants_1.ATTR_ARTICLE_SUB_TYPE_NAME);
        this.innerHTML = `
    <div class="secondary-container">
      ${this.articleSubTypeName} <span class="sub-article-id"></span> .
      <button class="edit">Edit</button>
    </div>
    <div class="content-viewer"></div>
    `;
        this.subArticleIdPlaceHolder = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'span.sub-article-id');
        this.contentViewer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.content-viewer');
        const editBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.edit');
        if (!this.isLogin) {
            editBtn.style.setProperty('display', 'none');
        }
        else {
            editBtn.addEventListener('click', e => {
                this.router.push(`/web/${this.routerPathGroup}/${this.routerSubPathGroup}/edit/${this.subArticleId}?mainArticleId=${this.mainArticleId}`);
            });
        }
    }
}
exports.SubArticleView = SubArticleView;
const defineSubArticleView = (name) => {
    (0, lib_es2015_1.defineIfUndefined)(name, SubArticleView);
};
exports.defineSubArticleView = defineSubArticleView;
